import React, { useState, useReducer, useContext, useEffect } from 'react'
import { DataGrid,GridToolbar  } from '@mui/x-data-grid';
import ColorButton from '../Buttons/ColorButton';
import { Box, TextField, Tooltip } from '@mui/material';
import { lockedReducer } from '../Utils/reducers';
import { InputLabel } from '@mui/material';
import MegaAutoComplete from '../Utils/megautocomplete';
import { NumbersOnly, CnpjMask } from '../Utils/IntegerOnly';
import validateData from './validateData';
import { externalComponent } from '../AppRoutes';
import { api } from '../../services/api';
import clsx from 'clsx';
import { formatCurrency } from '../Utils/functions';
import ConfirmationDialog from '../Utils/ConfirmationDialog';
import hasPermissions from '../Utils/groups';

let interval = 0
const Consultas = () => {

    const [clients, setClients] = useState([])
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({})

    const [state, setState] = useReducer(lockedReducer, {
        start:61,
        end:999,
        cnpj: '',
        uf:{id:"",label:""},
        startConsult:null,
        stopConsult:null,
        total_number:0,
        index_number:0,
        running:''
    })

    const validData = validateData(state)

    const { setLoader } = useContext(externalComponent)

    const searchClients = async () => {

        setLoader(true)
        const res = await api.post(`/consultas/`, state)
        if (res.status === 200) {
            const data = res.data

            setClients(data)
        }
        setLoader(false)

    }

    const searchCNPJ = async () => {
        
        setLoader(true)
            const res = await api.post(`/consulta_cnpj/`,state)
            if (res.status === 200) {
                const data = res.data
            }
        setLoader(false)
        setState({startConsult:null})
        searchStatus(false)

    }

    const stopConsult = async () => {
        
        const body = {
            clients:clients
        }

        setLoader(true)
            const res = await api.post(`/parar_consulta/`,body)
            if (res.status === 200) {
                const data = res.data
            }
        setLoader(false)
        setState({stopConsult:null})
        searchStatus(false)

    }

    

    const searchStatus = async (load) => {

        if(load) setLoader(true)
            const res = await api.post(`/consulta_status/`)

            if (res.status === 200) {

                const data = res.data

                let running_text = ''
                if(data.running ===  true) {
                    running_text = 'Em andamento'
                }else{
                    running_text = 'Desligado'
                }

                setState({total_number: data.total_number, index_number: data.index_number, running:running_text })
            }
        setLoader(false)

    }


    const columns = [
    
        {
            field: 'Uf',
            headerName: 'UF',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }
        },
        {
            field: 'Cidade',
            headerName: 'Cidade',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }
        },
        {
            field: 'nomeClienteGrupo',
            headerName: 'Nome/Grupo',
            flex: 0.2,
            cellClassName: () => {
                return clsx('descr', {
                    selected: true ,
                });
            }
        },
        {
            field: 'DiasSemComprar',
            headerName:  'Dias Sem Comprar',
            flex: 0.2,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }
        },
        {
            field: 'CnpjCpf',
            headerName:  'CNPJ',
            flex: 0.2,
            cellClassName: () => {
                return clsx('descr', {
                    selected: true ,
                });
            }
        },
        {
            field: 'fone',
            headerName:  'Telefone',
            flex: 0.2,
            cellClassName: () => {
                return clsx('descr', {
                    selected: true ,
                });
            }
        },
        {
            field: 'limiteCredito',
            headerName:  'Valor Limite',
            flex: 0.1,
            cellClassName: () => {
                return clsx('descr', {
                    selected: true ,
                });
            },
            renderCell: ({value}) => <Box>{formatCurrency(value)}</Box>
        },
        {
            field: 'limiteData',
            headerName:  'Data Limite',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }
        },
        {
            field: 'tipoLimite',
            headerName:  'Tipo Limite',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }
        },
        {
            field: 'status',
            headerName:  'Status',
            flex: 0.1,
            cellClassName: () => {
                return clsx('default-app', {
                    selected: true ,
                });
            }
        }

    ]

    const handleColumnVisibilityChange = (params) => {
        setColumnVisibilityModel(params)
        const stringColumn = JSON.stringify(params)
        localStorage.setItem('consults', stringColumn );
       };


    
 
     useEffect(() => {
         
        const storedColumns = localStorage.getItem('consults');
        if (storedColumns) {
            setColumnVisibilityModel(JSON.parse(storedColumns));
        }

        searchStatus(true)
        interval = setInterval(() => {
            searchStatus(false)
        }, 60000)

        return () => {
            clearInterval(interval)
        }
 
     },[])

    
    return(
        <>


            {state.startConsult &&
                <ConfirmationDialog
                    content={`Deseja iniciar a consulta dos CNPJ's?`}
                    handleClose={() => setState({ startConsult: null })}
                    onConfirm={() => searchCNPJ()}
                    
                />
            }

            {state.stopConsult &&
                <ConfirmationDialog
                    content={`Deseja parar a consulta dos CNPJ's?`}
                    handleClose={() => setState({ stopConsult: null })}
                    onConfirm={() => stopConsult()}
                />
            }


            <Box style={{display:'flex',flexDirection: 'column',border:'2px solid #e0e0e0',borderRadius:'5px', flexDirection:'row'}}>

                <Box sx={{flex:'0.5'}}>
                    <Box style={{gap:'1rem', display:'flex', padding:'1rem 1rem 0 1rem',flexDirection: 'row', alignItems:'flex-end'}}>

                        <Box>

                            <InputLabel>Num. Dias Sem Comprar:</InputLabel>

                            <Box sx={{display:'flex', flexDirection:'row', gap:'1rem', alignItems:'center'}}>

                                <InputLabel>Entre:</InputLabel>
                                <TextField
                                    id="start"
                                    type="text"
                                    label=""
                                    size='small'
                                    value={state.start}
                                    onChange={(e) => { setState({ 'start': NumbersOnly(e.target.value) }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                    sx={{width:'5rem'}}
                                />

                                <TextField
                                    id="end"
                                    type="text"
                                    label=""
                                    size='small'
                                    value={state.end}
                                    onChange={(e) => { setState({ 'end': NumbersOnly(e.target.value) }) }}
                                    fullWidth={false}
                                    inputProps={{
                                        maxLength: 100
                                    }}
                                    sx={{width:'5rem'}}
                                />
                            </Box>

                        </Box>
                        
                        <MegaAutoComplete
                                    
                                        value={state.uf}
                                        onChange={(data) => setState({ uf : data })}
                                        noOptionsText={'Digite para procurar uma UF'}
                                        timeoutUrl='/uf_list/'
                                        timeoutParam='label'
                                        label='UF'
                                        widthInput='15rem'
                                    
                                    />
                    

                    </Box>
                                    

                    <Box  style={{display:'flex', flexDirection:'column', padding: '1rem 1rem 1rem 1rem' }}>
                        <InputLabel>CNPJ:</InputLabel>
                        <TextField
                                            id="cnpj"
                                            type="text"
                                            label=""
                                            placeholder="00.000.000/0000-00"
                                            size='small'
                                            value={state.cnpj}
                                            onChange={(e) => { setState({ 'cnpj': CnpjMask(e.target.value) }) }}
                                            fullWidth={false}
                                            inputProps={{
                                                maxLength: 18
                                            }}
                                            sx={{width:'11rem'}}
                                />

                    </Box>
                   
                
                    <Box  style={{display:'flex', justifyContent:'space-between', padding: '1rem 1rem 1rem 1rem' }}>
                        

                        <Box style={{display:'flex',flexDirection: 'row', gap:'1rem'}}>
                            <Tooltip placement="left" title={validData}>
                                <ColorButton  className='realpadding' onClick={searchClients}
                                disabled={validData}
                                >Localizar
                                </ColorButton>
                            </Tooltip>

                        </Box>
                    

                    </Box>
                </Box>
                

                {
                
                    hasPermissions('administrador') ? (
                    <>
                        <Box sx={{borderLeft:'2px solid', height:'14rem',margin:'1rem 0 1rem 0', color:'#9f9a9a99'}}></Box> 

                        <Box sx={{flex:'0.5'}}>
                            <Box sx={{display:'flex',flexDirection:'column',padding:'1rem 1rem 1rem 1rem'}}>
                                <Box  sx={{display:'flex',flexDirection:'row', gap:'2rem'}}>
                                    <h4>Total da consulta: {state.total_number}</h4>
                                                
                                    <h4>Número da consulta: {state.index_number}</h4>
                                </Box>
        
                                <Box>
                                    <h4>Status: {state.running}</h4>
                                </Box>
        
                                <Box sx={{padding:'0.5rem 0 0 0',display:'flex',flexDirection:'row', gap:'1rem'}}>
        
                                    <ColorButton  className='realpadding' 
                                        disabled={validData}
                                        onClick={() => setState({ startConsult: true })}
                                        >Consulta CNPJ
                                    </ColorButton>
        
                                    <ColorButton  className='realpadding'
                                            disabled={validData}
                                            onClick={() => setState({ stopConsult: true })}
                                            >Parar consulta
                                    </ColorButton>
        
                                </Box>
                            </Box>
                                                
                        </Box>
                    </>
                    
                    )
                    :
                    null
                
                }

            </Box>
        
            <Box sx={{display:'flex',flexDirection:'column',gap:'3rem',height:'100%',overflow:'hidden'}}>

                <DataGrid
                        className='alterned_grid'
                        sx={{
                            '& .default-app.selected': {
                                justifyContent:'center',
                                alignItems:'center',
                            },
                            '& .descr': {
                                justifyContent:'start',
                                alignItems:'center',
                            },
                            height:"100%",
                            '& .MuiDataGrid-columnHeaderTitleContainer': {
                                justifyContent: 'center',
                            },
                            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
                                color:'black'
                            }
                        
                        }}

                        slots={{ toolbar: GridToolbar }}
                        rows={clients}                                                                                                                                                                                                                                                                                                                                                                                                                       
                        columns={columns}
                        pagination={false}
                        density='compact'
                        rowHeight={30}
                        columnVisibilityModel={columnVisibilityModel}
                        onColumnVisibilityModelChange={(newModel) =>
                            handleColumnVisibilityChange(newModel)
                        }
    
                    />

            </Box>
        </>
    )
}

export default Consultas;