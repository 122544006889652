const IntegerOnly = (value, min, max, returnString=false) => {
    const newvalue = value.replace(new RegExp(/[^\d]/, "ig"), "")
    if (returnString) return newvalue
    if (Number(newvalue) < min)
        return Number(min)
    if  (Number(newvalue) > max)
        return Number(max)
    return Number(newvalue)
}

export const FloatOnly = (value, min, max, returnString=false) => {
    const newvalue = value.replace(/[^-\d.]+/g, "")
    return newvalue
}

export const NumbersOnly = (value) => {
    return String(IntegerOnly(value, '', '', true))
}

export default IntegerOnly

export const CnpjMask = (value) => {
    const cnpjRegex = /^\d{2}(\.\d{3}){2}-\d{2}$/;
    const maxLength = 18; // Maximum allowed length for a CNPJ number
  
    if (value.replace(/\D+/g, '').length > maxLength) {
      // If the input value exceeds the maximum allowed length, truncate it
      value = value.substring(0, maxLength);
    }
  
    if (!cnpjRegex.test(value.replace(/\D+/g, ''))) {
      // If the input value does not match the CNPJ format, return an error message or a default value
    }
  
    const formattedCnpj = value.replace(/\D+/g, '').replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
    return formattedCnpj;
  };