import React, { useState, useContext, useEffect } from 'react';

import './menu.css'
import apptheme from '../theme/default';

import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptIcon from '@mui/icons-material/Receipt';
import { useNavigate } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import { Box, Button } from '@mui/material';
import Logout from '../Settings/Logout'
import { useLocation } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import AlignVerticalBottomIcon from '@mui/icons-material/AlignVerticalBottom';
import hasPermissions from '../Utils/groups'
import { externalComponent } from '../AppRoutes';
import Submenu from './submenu-item';
import SearchIcon from '@mui/icons-material/Search';

const menus = () => ({
  settings: { title: 'Configurações', icon: (<SettingsIcon />), permissions: false },
  pedido: { title: 'Pedido', icon: (<ReceiptIcon />),permissions: hasPermissions('administrador','admfilial','representante','funcRepresentante','LiberacoesPedido'),submenu: [
    {title:'Painel',redirect:'/painel',permissions: hasPermissions('administrador','admfilial','representante','funcRepresentante','LiberacoesPedido')},
    {title:'Novo pedido',redirect:'/novopedido',permissions: hasPermissions('administrador')}
  ]},
  distribuicao: { title: 'Distribuição', icon: (<AlignVerticalBottomIcon />), permissions: hasPermissions('administrador') },
  cadastros: { title: 'Cadastros', icon: (<DashboardCustomizeIcon />), permissions: hasPermissions('administrador') },
  consultas: { title: 'Consultas', icon: (<SearchIcon />), permissions: hasPermissions('administrador','representante','funcRepresentante') },

})

const exit = () => ({
  exit: { title: 'Sair', icon: (<LogoutIcon />), overthrow: { onClick: Logout }, permissions: true }
})



const MenuComponent = () => {
  const {width,isMobile} = useContext(externalComponent)
  const [showMenu, setShowmenu] = useState(false)
  

  const selectedTheme = {
    sx: {
      color: apptheme.secondaryColor,
      backgroundColor: `${apptheme.tertiaryColor} !important`
    },
    className: 'main-menu-button selected'
  }

  const menuTheme = {
    sx: { color: apptheme.secondaryColor },
    className: 'main-menu-button'
  }

  const decideTheme = (actualRoute) => {
    if (actualRoute === route.pathname)
      return { ...selectedTheme }
    return { ...menuTheme }
  }

  const decideThemeSubmenu = (routeList) => {
    const selected = routeList.includes(route.pathname) 
    if (selected)
      return { ...selectedTheme }
    return { ...menuTheme }
  }

  const route = useLocation()
  const navigate = useNavigate();

  useEffect(()=>{
    if (!('backtobase' in window))
      window.backtobase = () => {}
  }, [])


  return (
    <Box className={`${(isMobile || showMenu) ? 'overlay' : ''}`} sx={{height: (!isMobile || showMenu) ? '100%' : 'max-content'}}>
    {(!isMobile || showMenu) ? 
    <Box className='lateral-menu' sx={{ backgroundColor: apptheme.primaryColor, height: "100%" }}>
      <Box className='inside-menu'>
        <Button
        onClick={() => navigate(`/home`)}
        children={<img src='v10.png' alt='v10' />}
        >
        </Button>
        {Object.entries(menus()).map(([key, value]) =>
          <>
            {value.permissions ?
            <>
              {!value.submenu ? <Button
                {...decideTheme(`/${key}`)}
                onClick={() => {window?.backtobase(); navigate(`/${key}`); setShowmenu(false)}}
                children={<>{value.icon}<span>{value.title}</span></>}
                {...value.overthrow}
              >
              </Button> 
               :
                <Submenu config={value} theme={decideThemeSubmenu}/>
              }
            </>
              : null}
          </>
        )}
       
      </Box>
      <Box className='inside-menu'>
        {Object.entries(exit()).map(([key, value]) =>
          <>
            {value.permissions ?
              <Button
                {...decideTheme(`/${key}`)}
                onClick={() => navigate(`/${key}`)}
                children={<>{value.icon}<span>{value.title}</span></>}
                {...value.overthrow}
              >
              </Button> : null}
          </>
        )}
      </Box> 
    </Box>
    :
    <Box className='inside-menu'>
      <Button
        sx={{
          color: apptheme.tertiaryColor,
        }} 
        onClick={()=>{setShowmenu(true)}}
        children={<MenuIcon />}
        className='main-menu-button hamburguer'
        >
       </Button>
    </Box>
    }
    {showMenu && <Box onClick={()=>setShowmenu(false)} className='backdrop'></Box>} 
    </Box>
  )
};

export default MenuComponent;