import React, { useState, useEffect } from 'react'
import ColorButton from '../Buttons/ColorButton'
import apptheme from '../theme/default'
import { Box } from '@mui/material'
import './styles.css'
import DefButton from '../Buttons/defbutton'
import ReplyIcon from '@mui/icons-material/Reply';
import { useWindowSize } from "@uidotdev/usehooks";
import hasPermissions from '../Utils/groups'
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import Colaboradores from './Colaborador'




const MenuList = () => {

    // const user = getSession()
    // const permissions = user?.permissions

    return ({
        'colaboradores': {
            title: 'Colaboradores',
            icon: (<PersonAddIcon />),
            obj: (<Colaboradores />),
            permission: (hasPermissions('administrador'))
        }
    })
}

const CalcWidthSize = (size) => {
    const threshold = size?.width < 768 ? 200 : 400
    const calcSize = Math.round(size?.width / threshold)
    if (!calcSize)
        return 2
    return calcSize
}

const Cadastros = () => {

    const [selectedTab, setSelectedTab] = useState()
    const size = useWindowSize();

    const menulist = MenuList()

    const calcSize = CalcWidthSize(size)

    const itens = Object.entries(menulist).filter(([key, value]) => value.permission)

    useEffect(()=>{
        window.backtobase = () => setSelectedTab()
    }, [])

    return (
        <>
            {!selectedTab ?
                <>
                    <Box className='gridBox' sx={{ gridTemplateColumns: `repeat(${calcSize}, minMax(0, 1fr))` }}>
                        {itens.map(([key, value]) => (
                            <ColorButton
                                onClick={() => setSelectedTab(key)}
                                sx={{ height: '8rem', flex: 1 , visibility: !value.obj ? 'hidden' : 'visible' }}
                                fColor={apptheme.primaryColor}
                                sColor={apptheme.tertiaryColor}>
                                <Box className='flexbox'>
                                    {value.icon}
                                    {value.title}
                                </Box>
                            </ColorButton>

                        ))}
                    </Box>
                </>
                :
                <>
                    <Box className='settings-back-button'>
                        <DefButton
                            onClick={() => setSelectedTab()}
                        >
                            <ReplyIcon />
                        </DefButton>
                    </Box>
                    {MenuList()[selectedTab].obj}
                </>
            }

        </>
    )
}

export default Cadastros;